import { useState } from "react"

import { getTimeString } from "src/components/DeviceSettingsDialog/NoiseThresholds/formatTimeRange"
import { SliderTimeRange } from "src/components/Settings/EditField/SliderTimeRange"
import { translateSliderValue } from "src/components/Settings/utils"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { formatTimeString } from "src/utils/l10n"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingTimeRange({
  title,
  description,
  value,
  onSave,
  ...props
}: ISettingContainerBaseProps & {
  onSave(newVal: string): TSettingContainerOnSaveReturnType
  value: string
}) {
  const [saveValue, setSaveValue] = useState(value)
  const times = translateSliderValue(value)
  const clockType = useGetUser().clock_type

  function timeRange() {
    const val0 = formatTimeString(getTimeString(times[0]), clockType)
    const val1 = formatTimeString(getTimeString(times[1]), clockType)
    return `${val0} - ${val1}`
  }

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={() => {
        return onSave(saveValue)
      }}
      displayValue={timeRange()}
      InputComponent={() => (
        <SliderTimeRange
          value={value}
          clockType={clockType ?? "24"}
          setValue={setSaveValue}
        />
      )}
      {...props}
    />
  )
}
