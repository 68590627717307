import { useState } from "react"

import { Select, SelectProps } from "@material-ui/core"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingDropdown({
  title,
  description,
  value,
  onSave,
  selectProps,
  ...props
}: ISettingContainerBaseProps & {
  selectProps?: SelectProps
  onSave(newVal: ISettingDropdownOption): TSettingContainerOnSaveReturnType
  value: ISettingDropdownOption
  options: ISettingDropdownOption[]
}) {
  const [saveValue, setSaveValue] = useState(value)

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={() => {
        return onSave(saveValue)
      }}
      displayValue={String(value.name)}
      InputComponent={({ saving }) => (
        <Input
          valueInitial={value.value}
          setSaveValue={setSaveValue}
          disabled={saving}
          options={props.options}
          {...selectProps}
        />
      )}
      {...props}
    />
  )
}

function Input({
  valueInitial,
  setSaveValue,
  options,
  ...selectProps
}: {
  valueInitial: (typeof options)[number]["value"]
  setSaveValue(newVal: ISettingDropdownOption): void
  options: ISettingDropdownOption[]
} & SelectProps) {
  const [_value, _setValue] = useState(valueInitial)
  return (
    <Select
      native
      value={_value}
      onChange={(ev) => {
        const newVal = ev.target.value as (typeof options)[number]["value"]
        _setValue(newVal)
        setSaveValue(options.find((o) => o.value === newVal) ?? options[0])
      }}
      disabled={selectProps.disabled}
      fullWidth
      {...selectProps}
    >
      {options.map((option) => (
        <option
          value={option.value}
          key={option.value}
          disabled={option.disabled}
          hidden={option.hidden}
        >
          {option.name}
        </option>
      ))}
    </Select>
  )
}

export interface ISettingDropdownOption {
  value: string
  name: string
  disabled?: boolean
  hidden?: boolean
}
